import React from "react";
import Layout from "../components/layout";
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { StaticImage } from "gatsby-plugin-image";
import Section from "../components/section";
import { useSiteMetadata } from '../hooks/use-site-metadata';
import SEO from "../components/seo";


const ContactUs = () => {
    const { twitterUrl, linkedinUrl, facebookUrl, youtubeUrl } = useSiteMetadata();

    return (
        <Layout>
            <section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/20 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="features" src="../assets/Contact-Us-Hero.png" className="w-full h-full" imgClassName="object-cover w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Contact Us
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <Section>
                <div className="grid grid-cols-1 lg:grid-cols-2 text-center gap-16">
                    <div className="relative border border-borderLight p-10 rounded-md">
                        <div className="absolute top-0 left-0 w-full h-[4px] bg-coeusRed rounded-tr-md rounded-tl-md"></div>
                        <div className="flex flex-col items-center space-y-4">
                            <div className="inline-flex items-center">
                                <EnvelopeIcon className="h-8 w-8 mr-2 inline" />
                                <h1 className="text-3xl">Email Us</h1>
                            </div>
                            <a href="mailto:info@coeus.chat" className="underline text-xl">info@coeus.chat</a>
                        </div>
                    </div>

                    <div className="relative border border-borderLight p-10 rounded-md">
                        <div className="absolute top-0 left-0 w-full h-[4px] bg-coeusRed rounded-tr-md rounded-tl-md"></div>
                        <h1 className="text-3xl">Follow Us on Social Media</h1>
                        <div className="flex space-x-2 pt-4 justify-center">
                            <a href={linkedinUrl} className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center">
                                <StaticImage
                                    src="../assets/icon-linkedin.svg"
                                    placeholder="tracedSVG"
                                    loading="lazy"
                                    width={16}
                                    height={15}
                                    alt="linkedin icon"
                                />
                            </a>
                            <a href={facebookUrl} className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center">
                                <StaticImage
                                    src="../assets/icon-facebook.svg"
                                    placeholder="tracedSVG"
                                    loading="lazy"
                                    width={8}
                                    height={18}
                                    alt="facebook icon"
                                />
                            </a>
                            <a href={twitterUrl} className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center">
                                <StaticImage
                                    src="../assets/icon-twitter.svg"
                                    placeholder="tracedSVG"
                                    loading="lazy"
                                    width={16}
                                    height={14}
                                    alt="twitter icon"
                                />
                            </a>
                            <a href={youtubeUrl} className="bg-gray-700 hover:bg-gray-500 transition-colors rounded-full h-8 w-8 flex items-center justify-center">
                                <StaticImage
                                    src="../assets/icon-youtube.svg"
                                    placeholder="tracedSVG"
                                    loading="lazy"
                                    width={18}
                                    height={18}
                                    alt="youtube icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

export default ContactUs;

export const Head = () => {
    return (
        <SEO
            title="Contact Us"
            description="See how Coeus can work for you. Thanks for reaching out to us! We are excited to answer your questions about our Coeus solution!"
        ></SEO>
    );
};
